<template>
  <AdminView>
    <h1 class="title-1">Coachings</h1>

    <div class="buttons-container">
      <div class="search">
        <Input
          title="Rechercher"
          v-model="search"
        />
      </div>

      <router-link :to="{ name: 'AddCoaching' }">
        <Button
          title="Ajouter"
          type="secondary"
        >
          <SVGPlus />
        </Button>
      </router-link>
    </div>

    <table>
      <thead>
        <tr>
          <th></th>
          <th>Image</th>
          <th class="sortable" @click="sort('name')">Titre</th>
          <th>Categorie</th>
          <th>Sous catégorie</th>
          <th>Durée</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(coaching, i) in sortedCoachings" :key="i">
          <td class="edit">
            <router-link :to="{ name: 'Coaching', params: { id: coaching.id } }" title="Modifier">
              <SVGEdit />
            </router-link>
          </td>
          <td class="image">
            <img :src="coaching.image" v-if="coaching.image" />
          </td>
          <td>{{ coaching.name }}</td>
          <td>{{ coaching.category }}</td>
          <td>{{ coaching.subCategory }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </AdminView>
</template>

<script>
import AdminView from '@/components/AdminView'
import Input from '@/components/Forms/Fields/Input'
import Button from '@/components/Button'
import SVGPlus from '@/assets/icons/plus.svg'
import SVGEdit from '@/assets/icons/edit.svg'

export default {
  name: 'CoachingList',
  components: {
    AdminView, Input, Button, SVGPlus, SVGEdit
  },
  data () {
    return {
      search: '',
      sortBy: 'name',
      coachings: [
        {
          id: '1',
          name: 'Coaching 1',
          image: 'https://lifebloomfrontdev.blob.core.windows.net/files/exo 1 image.png',
          category: 1,
          subCategory: 1,
          video: 'https://lifebloomfrontdev.blob.core.windows.net/files/exo 1 consigne.mp4',
          videoExercise: 'https://lifebloomfrontdev.blob.core.windows.net/files/exo 1 déroulé.mp4',
          advice: 'https://lifebloomfrontdev.blob.core.windows.net/files/exo 1 conseil 1.m4a'
        },
        {
          id: '2',
          name: 'Coaching 2',
          image: 'https://lifebloomfrontdev.blob.core.windows.net/files/exo 1 image.png',
          category: 1,
          subCategory: 1,
          video: 'https://lifebloomfrontdev.blob.core.windows.net/files/exo 1 consigne.mp4',
          videoExercise: 'https://lifebloomfrontdev.blob.core.windows.net/files/exo 1 déroulé.mp4',
          advice: 'https://lifebloomfrontdev.blob.core.windows.net/files/exo 1 conseil 1.m4a'
        }
      ]
    }
  },
  methods: {
    sort (type) {
      if (this.sortBy === type) {
        this.sortBy = '-' + type
      } else {
        this.sortBy = type
      }
    }
  },
  computed: {
    filteredCoachings () {
      return this.coachings.filter(p =>
        p.name.toLowerCase().includes(this.search.toLowerCase())
      )
    },
    sortedCoachings () {
      const order = this.sortBy.startsWith('-') ? -1 : 1
      const sortBy = order < 0 ? this.sortBy.substring(1) : this.sortBy

      return [...this.filteredCoachings].sort((a, b) => {
        return a[sortBy] > b[sortBy] ? 1 * order : -1 * order
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 40px 0;

  div {
    flex: 0;
  }
}

.coachings-list{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

table{
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;

  thead{
    font-size: 1.1rem;
    font-weight: 420;
    text-align: left;
    background: $main-color;
    color: $white-color;

    .sortable{
      cursor: pointer;
      transition: all 0.25s;

      &:hover{
        opacity: 0.75;
      }
    }
  }

  tbody{
    tr{
      &:nth-child(2n){
        background: #f7f7f7;
      }
    }
  }

  th, td{
    padding: 8px 10px;
    vertical-align: middle;
  }

  th{
    padding: 10px;
    user-select: none;
  }

  .image{
    padding: 2px;
    width: 50px;
    height: 38px;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 5px;
    }
  }

  .icon{
    text-align: center;

    svg{
      width: 20px;
      color: $main-color;
    }
  }

  .edit{
    a{
      display: block;

      svg{
        width: 20px;
        transition: all 0.25s;
      }

      &:hover{
        svg{
          fill: $main-color;
        }
      }
    }
  }
}
</style>
